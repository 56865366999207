import produce, { enableES5 } from 'immer';
import { AppState, AppAction } from '@/types/AppState';
// immer uses Proxy, but if that's not available the ES5 plugin needs to be enabled
/* istanbul ignore if */
if (typeof Proxy === 'undefined') {
  // thanks, IE 11
  enableES5();
}

export const initialAppState: AppState = {};

export const rootReducer = produce((draft: AppState, action: AppAction) => {
  switch (action.type) {
    case 'setFlowType':
      draft.flowType = action.flowType;
      break;
    case 'setUserLocation':
      draft.userLocation = action.location;
      break;
    default:
      break;
  }

  return draft;
});
