import { useEffect, useState } from 'react';
import { getRXTrackingUrl } from './utils';

interface RXTrackingPixelProps {
  seoPageId: number;
}

function RXTrackingPixel({ seoPageId }: RXTrackingPixelProps) {
  const [RXTrackingURL, setRXTrackingURL] = useState<string>();

  useEffect(() => {
    setRXTrackingURL(getRXTrackingUrl(seoPageId));
  }, [seoPageId]);

  if (!RXTrackingURL) {
    return null;
  }

  return <img src={RXTrackingURL} width={1} height={1} alt="" style={{ display: 'none' }} />;
}

export default RXTrackingPixel;
